<template>
  <div class="app-container career-test">
    <ul class="test-box">
      <li v-if="item.open" class="test-item" @click="toTest(item)" v-for="(item, index) in testTypeList" :key="index">
        {{ item.name }}
        <p class="simple-introduction">{{ item.introduction }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
import { getStore } from '@/utils/utils'
export default {
  name: "career-test",
  data() {
    return {
      testTypeList: [
        {
          name: 'MBTI测评',
          testType: 1,
          path: '/MBTI',
          open: true,
          introduction: 'MBTI职业性格测试是国际最为流行的职业人格评估工具，作为一种对个性的判断和分析，是一个理论模型，从纷繁复杂的个性特征中，归纳提炼出4个关键要素——动力、信息收集、决策方式、生活方式，进行分析判断，从而把不同个性的人区别开来。'
        },
        {
          name: '霍兰德',
          testType: 2,
          path: '/holland-test',
          open: true,
          introduction: '霍兰德职业兴趣自测（Self-Directed Search）是由美国职业指导专家霍兰德（John Holland）根据他本人大量的职业咨询经验及其职业类型理论编制的测评工具。'
        },
        {
          name: '中学生人格测评',
          testType: 3,
          path: '/personality-test',
          open: true,
          introduction: '人格（Personality）起源于古希腊语persona。最初是指古希腊戏剧演员在舞台演出时所戴的面具。在进行生涯决策时，如果能够充分了解自己的人格特质，透过“面具”看清自己，做出最符合人格特质的决策，就可以扔掉“面具”，做真实的自己。'
        },
        {
          name: '新高考选科测评',
          testType: 4,
          path: '/exam-test',
          open: true,
          introduction: '新高考选科可以说是高中的第一次重大选择，是今后人生道路的分水岭，选择什么样的科目将影响大学专业选择，专业选择又与未来的职业有着紧密的联系，人生职业的方向从而关乎到人生。新高考选科指导系统，以生涯规划测评数据为基础，根据学生的兴趣、爱好、性格及潜能分析，对学生实施选科指导，具有科学、具体、针对性等特性，旨在解决学子在面对新高考选科中遇到的各种困扰。'
        },
        {
          name: '升学路径测评',
          testType: 5,
          path: '/enter-path',
          open: true,
          introduction: '随着高考改革的进行，相对单一的升学途径变得越来越多元，升学的途径已经达到20多种，为考生和家长提供了更多的选择。并且从发展趋势来说，非裸分录取的机率也越来越大，新高考改革的浙江省，北大清华综合评价录取的人数，已经超过裸分录取的人数。此外艺术类、体育类招生、综合评价录取、军事院校和公安类招生、航海类招生、免费师范生、免费医学生、三大专项计划、高水平艺术团、高水平运动队、民族班等众多途径供考生选择，如果考生放弃这些机会，无疑降低录取机会。通过本测试，你可以了解更多的升学渠道，找到最适合自己的升学之路。'
        },
        // {
        //   name: '文将语文素养测评',
        //   path: 'https://wen.xuezheyoushi.com/evaluation/paper',
        //   externalSite: true,
        //   open: EnableWJTest,
        //   introduction: '文将语文素养测评'
        // },
      ]
    }
  },
  mounted() { },
  methods: {
    toTest(item) {

      if (item.externalSite) {
        this.$store.dispatch('user/getInfo').then(res => {
          const externalPath = item.path + '?type=1&code=wj-am2m5YwP&name=' + res.data.Name + '&phone=' + res.data.Mobile + '&school=' + res.data.SeniorMiddleSchool
          window.open(externalPath)
        })
      } else {
        let routerUrl = this.$router.resolve({
          path: item.path
        })
        window.open(routerUrl.href)
      }

      // let temp = getStore('LoginData')

      // if (temp) {

      // } else {
      //   this.$message({
      //     type: 'error',
      //     message: '请先登录！',
      //     showClose: true,
      //     center: true
      //   })

      //   setTimeout(() => {
      //     this.$router.push('/login')
      //   }, 300)
      // }
    }
  }
}
</script>
<style scoped lang="less">
.career-test {
  padding: 2rem 5rem;
  .test-box {
    .test-item {
      width: 100%;
      padding: 2rem 3rem 2rem;
      margin-bottom: 2rem;
      text-align: center;
      border-radius: 3px;
      font-size: 30px;
      cursor: pointer;
      transition: box-shadow .2s linear;
      color: rgba(var(--themecolor),1);
      background-color: #ebeff8;
      box-shadow: 0 2px 8px 0 rgba(87, 113, 173, 0.15);
      &:hover {
        box-shadow: 0 2px 8px 0 rgba(87, 113, 173, 0.5);
        background-color: #e3eaf8;
      }
      .simple-introduction {
        font-size: 16px;
        color: #888;
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
}
</style>
  